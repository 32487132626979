import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Hero from "../components/hero";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SocialLinks from "../components/SocialLinks";
import {
  ContactWrapper,
  Label,
  WorksListContainer,
} from "../components/StyledComps";
import WorkListItem from "../components/WorkListItem";
import { featured } from "../data/data";

const IndexPage = ({ data }: any) => (
  <Layout>
    <SEO title="Makoto Dejima | Design and Development" />
    <Hero />
    <Label>
      Featured Work <Link to="/work/">Show More Works</Link>
    </Label>

    <WorksListContainer>
      {Object.keys(featured).map((id) => {
        return (
          <WorkListItem
            key={featured[id].id}
            title={featured[id].title}
            client={featured[id].client}
            type={featured[id].type}
            keywords={featured[id].keywords}
            path={featured[id].path}
            url={featured[id].url}
          >
            {featured[id].isVideo ? (
              <video autoPlay loop muted playsInline>
                <source src={data[id].publicURL} type="video/mp4" />
              </video>
            ) : (
              <Img
                alt={featured[id].id}
                fluid={data[id].childImageSharp.fluid}
              />
            )}
          </WorkListItem>
        );
      })}
    </WorksListContainer>
    <ContactWrapper>
      <SocialLinks />
    </ContactWrapper>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    the_light_of_tokyo: file(
      relativePath: { eq: "the_light_of_tokyo/the_light_of_tokyo.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mera: file(relativePath: { eq: "mera.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    satte: file(relativePath: { eq: "satte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    manhattan_portage: file(
      relativePath: { eq: "manhattan_portage/manhattan_portage.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robotex: file(relativePath: { eq: "robotex/robotex.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sobasquare: file(relativePath: { eq: "sobasquare.mp4" }) {
      publicURL
    }
    naotatsu_kaku: file(relativePath: { eq: "naotatsu_kaku.mp4" }) {
      publicURL
    }
  }
`;
